import React from "react";
import { BasicDataContext } from "./context";
import { Forloadandnavigate } from "../../functions/useNaviLoadBoth";
import PageDataLoading from "../../pages/pageDataLoading";

const BasicDataProvider = ({ children = <div /> }) => {
  const [isloading, setisloading] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => {
      setisloading(false);
    }, 4000);
  }, []);
  return (
    <BasicDataContext.Provider value={{ isloading }}>
      {!isloading ? children : <PageDataLoading />}
      <Forloadandnavigate />
    </BasicDataContext.Provider>
  );
};

export default BasicDataProvider;
