import axios from "axios";

export const onlineApisUrl = "https://lesvoyageurs-api.vercel.app";

export const localApisUrl = window.location.hostname.includes("lesvoyageurs")
  ? onlineApisUrl
  : "http://" + window.location.hostname + ":5010";

export const usedBaseUrl = localApisUrl;

export const api = axios.create({
  baseURL: usedBaseUrl + "/v1/service/location",
});