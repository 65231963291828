import axios from "axios";
import { getUrl, postUrl } from "../../../../configs/apiRequest";

export async function getOsmLocationFromCoords({
  latitude = 0,
  longitude = 0,
}) {
  let url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&addressdetails=1&format=json`;
  let reponse;
  try {
    reponse = await axios.get(url);
  } catch (error) {}
  let result = !!!reponse?.data
    ? { success: false, message: "Requete invalide !", data: null }
    : reponse.data;
  return result;
}

export function verifyStation(id: string) {
  return getUrl("/station/verify?id=" + id);
}

export function updateStationLocation(id: string, update: any) {
  // AxiosPostUrl(
  //     "/company/gare/geolocation/new",
  //     { location_id, location_data, location_coords },
  //     (reponse) => {
  //       if (reponse) {
  //         cb(reponse);
  //       } else {
  //         cb({
  //           success: false,
  //           data: {},
  //           msg: "Erreur survenue !",
  //         });
  //       }
  //     },
  //     function (reponse) {
  //       cb({
  //         success: false,
  //         data: {},
  //         msg: "Erreur survenue !",
  //       });
  //     }
  //   );
  return postUrl("/station/update?id=" + id, update);
}
