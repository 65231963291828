import React from "react";
import { useNaviLoadBoth } from "../../../../functions/useNaviLoadBoth";

const GeoolocationError = () => {
  return (
    <>
      <span className="flex font-bold -my-3 text-[22px]">Lien incorrecte</span>
      <RedirectionToOtherSite lien="https://www.lesvoyageurs.ci" timeOut={5} />
    </>
  );
};

export function RedirectionToOtherSite({
  lien = "",
  msg = "Vous serez redirigé dans quelques instants...",
  timeOut = 3,
  ...arg
}) {
  const navigate = useNaviLoadBoth;

  React.useEffect(() => {
    setTimeout(() => {}, timeOut * 1000);
    navigate(lien);
  }, [navigate, lien, timeOut]);
  return <span {...arg}>{msg}</span>;
}

export default GeoolocationError;
