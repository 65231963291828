import React from "react";
import { ModalsContext } from "./context";
import { useModalHook } from "../../functions/useModalHook";
import { useElementDimensions } from "../../functions/getAnyElementSize";
import { useSearchParams } from "react-router-dom";

const ModalsProvider = ({ children = <div /> }) => {
  const [data, setData] = React.useState(Object());
  const [checkData, setDataChecking] = React.useState(false);
  const { ref, dimensions } = useElementDimensions<HTMLDivElement>();
  const [URLSearchParams] = useSearchParams();
  const query = URLSearchParams;
  const modalType = query.get("mdl");
  // React.useEffect(() => {}, [query]);
  return (
    <ModalsContext.Provider
      value={{ data, setData, checkData, setDataChecking }}
    >
      <div ref={ref} className={`flex flex-1`}>
        <div className="flex flex-1">{children}</div>
        {!!modalType ? (
          <SwitchModals
            modalType={modalType}
            size={{ h: dimensions.height, w: dimensions.width }}
          />
        ) : null}
      </div>
    </ModalsContext.Provider>
  );
};

function SwitchModals({ modalType = "", size = { h: 0, w: 0 } }) {
  switch (modalType) {

    default:
      return <div className="none" />;
  }
}

export function ModalContainer({
  children = <div />,
  size = { h: 0, w: 0 },
  showCloseButton = true,
}) {
  const [startBlur, setStartBlur] = React.useState(false);
  const { closeModal } = useModalHook();
  const refBack = React.useRef<HTMLDivElement>(null);
  const refFront = React.useRef<HTMLDivElement>(null);
  function handleClickOutside(event: any) {
    if (!!refBack.current && !!refFront.current && !!event.target) {
      if (
        refBack.current.contains(event.target) &&
        !refFront.current.contains(event.target)
      ) {
        closeModal();
      }
    }
  }
  const blurBackground = () => {
    setStartBlur(!startBlur);
  };
  React.useEffect(() => {
    setTimeout(() => {
      blurBackground();
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });
  return (
    <div
      ref={refBack}
      className={`fixed flex bg-[rgba(0,0,0,0.25)] justify-center items-center ${
        startBlur ? "backdrop-blur-[4px]" : ""
      } transition-backdrop-filter duration-500 ease-out z-[995]`}
      style={{ height: "100vh", width: "100vw" }}
    >
      <div
        ref={refFront}
        className="flex justify-end items-start relative z-[995]"
      >
        {children}
        {showCloseButton && (
          <button
            onClick={function () {
              closeModal();
            }}
            className="absolute z-auto flex top-3 right-3 p-[6px] bg-dimgray/20 rounded-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
}

export default ModalsProvider;
