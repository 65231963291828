import React from "react";
import { RedirectionToOtherSite } from "../geolocation/contents/check/error";

const Home = () => {
  return (
    <RedirectionToOtherSite timeOut={4} lien="https://www.lesvoyageurs.ci" />
  );
};

export default Home;
