import moment from "moment";
import forge from "node-forge";
import { ToastPosition, toast } from "react-toastify";

export const primaryColor = "#f56b2a";
export const hoverColor = "rgb(249 154 14)";
export function colorParamsRegulatorFunc(
  color: string,
  prefix: string,
  defaultColor: string
) {
  return color.length !== 0
    ? color.startsWith(`${prefix}-`)
      ? color.trim()
      : color.startsWith("#") ||
        color.startsWith("rgb") ||
        color.startsWith("Hex")
      ? `${prefix}-[${color.trim()}]`
      : `${prefix}-${color.trim()}`
    : defaultColor;
}

export const orangeCCDM = "#F08400";
export const orangeHoverCCDM = "#DD7403";
export const isSimpleText = (string: string) => /^[a-zA-Z- ']*$/.test(string);
export const isNumber = (string: string) => /^[0-9]+$/.test(string);
export const isLowerCase = (string: string) => /^[a-z]*$/.test(string);
export const isUpperCase = (string: string) => /^[A-Z]*$/.test(string);
export const isPhoneNumber = (string: string) => /^[0-9]{6,14}$/.test(string);
export const isEmail = (string: string) =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
    string
  );
export const isPassword = (string: string) =>
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]{10,}$/.test(string);
export const validatePasswordMatch = (
  password: string,
  confirmPassword: string
) => {
  return password === confirmPassword;
};
export function isPastDate(dateString: string) {
  const now = moment(); // Date et heure actuelles
  const date = moment(dateString); // Date fournie en argument

  return date.isBefore(now); // Retourne vrai si la date est avant maintenant
}
export function isFutureDate(dateString: string) {
  const now = moment(); // Date et heure actuelles
  const date = moment(dateString); // Date fournie en argument

  return date.isAfter(now); // Retourne vrai si la date est après maintenant
}

export function encodeBase64Url(str: string) {
  // Encode en base64
  const base64 = forge.util.encode64(str);

  // Remplace les caractères spéciaux par leur équivalent en base64url
  return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
}
export function decodeBase64Url(str: string) {
  // Remplace les caractères spéciaux par leur équivalent en base64
  str = str
    .replace(/-/g, "+")
    .replace(/_/g, "/")
    .padEnd(str.length + ((4 - (str.length % 4)) % 4), "=");

  // Décodage en base64
  return forge.util.decode64(str);
}

export const ANY = Object();
// Tailwind constant
export const pageClassname =
  "flex flex-col w-full pt-[25px] max-lg:pt-[30px] pb-8 px-5 2xl:px-20";

export const ToastErrorNotifier = ({
  message = "",
  duration = 5000,
  position = "top-center",
}) => {
  toast.error(message, {
    position: position as ToastPosition,
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const ToastSuccessNotifier = ({
  message = "",
  duration = 5000,
  position = "top-center",
}) => {
  toast.success(message, {
    position: position as ToastPosition,
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const ToastWarnNotifier = ({
  message = "",
  duration = 5000,
  position = "top-center",
}) => {
  toast.warn(message, {
    position: position as ToastPosition,
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};
