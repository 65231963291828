import React from "react";
import voyageurLogo from "../../../../assets/voyageurCoral.svg";
import { ANY, ToastErrorNotifier, primaryColor } from "../../../../constants";
import { useSearchParams } from "react-router-dom";
import { useNaviLoadBoth } from "../../../../functions/useNaviLoadBoth";
import LoadingData from "./loading";
import { useOneEffect } from "../../../../hooks/useOnEffect";
import {
  getOsmLocationFromCoords,
  updateStationLocation,
  verifyStation,
} from "./functions";
import { decrypt } from "../../../../configs/encryption";
import GeoolocationError from "./error";
import moment from "moment";
import CryptoJS from "crypto-js";

const CheckGeolocation = () => {
  const [URLSearchParams] = useSearchParams();
  const token = URLSearchParams.get("token");
  const decryptedData = !!token
    ? decrypt(
        CryptoJS.enc.Base64url.parse(token).toString(CryptoJS.enc.Utf8),
        process.env.REACT_APP_Key,
        process.env.REACT_APP_Iv
      )
    : null;
  const id = decryptedData?.id ?? "";
  const timestamp = parseInt(decryptedData?.timestamp ?? "0");
  const timestampExpired =
    moment().valueOf() >= moment(timestamp).add(1, "hour").valueOf();
  const [station, setStation] = React.useState<any>(null);
  const [isLoadingData, setIsLoadingData] = React.useState(true);
  const navigate = useNaviLoadBoth;
  const [locationCoords, setlocationCoords] = React.useState({
    latitude: 0,
    longitude: 0,
  });
  const [reponseData, setReponseData] = React.useState(ANY);
  const [isGeolocating, setIsGeolocating] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [geolocationState, setGeolocationState] = React.useState({
    state: true,
    msg: "Assurez-vous d'etre a la gare et activer votre gps ou la localisation.",
  });

  const requestGeolocationPermission = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: "geolocation",
      });
      if (["granted", "prompt"].includes(permissionStatus.state)) {
        // Permission already granted, proceed to get the geolocation
        getGeolocation();
      } else {
        // la localisation a ete desactiver sur ce site veuillez a la reactiver
        setGeolocationState({
          state: false,
          msg: "Veuillez autoriser la localisation pour ce site puis recharger la page.",
        });
      }
    } catch (error) {
      console.error("Error requesting géolocation permission:", error);
      setGeolocationState({
        state: false,
        msg: "Veuillez reesayer svp! Et si l'erreur persiste changer de navigateur puis reesayer.",
      });
    }
  };

  const getGeolocation = () => {
    setIsGeolocating(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setlocationCoords({ latitude, longitude });
        getOsmLocationFromCoords({ latitude, longitude }).then((reponse) => {
          setReponseData(reponse);
          setIsGeolocating(false);
        });
      },
      (error) => {
        setIsGeolocating(false);
        setGeolocationState({
          state: false,
          msg: "Désolé une erreur s'est produite, veuillez reésayer svp!",
        });
      },
      { enableHighAccuracy: true }
    );
  };

  const updateStation = () => {
    setIsUpdating(true);
    updateStationLocation(id, {
      station_locality: reponseData.display_name,
      station_geocoords: {
        lat: locationCoords.latitude,
        lon: locationCoords.longitude,
      },
    }).then((res) => {
      setIsUpdating(false);
      if (res.success) {
        setSaveSuccess(true);
      } else {
        ToastErrorNotifier({ message: res.message.toString() });
      }
    });
  };

  function VerifyStation() {
    setIsLoadingData(true);
    verifyStation(id).then((res) => {
      setIsLoadingData(false);
      if (res.success) {
        setStation(res.data);
      } else {
        ToastErrorNotifier({ message: res.message.toString() });
      }
    });
  }

  useOneEffect(() => {
    if (!!decryptedData) {
      VerifyStation();
    }
  }, []);

  React.useEffect(() => {
    if (saveSuccess) {
      setTimeout(() => {
        navigate("https://www.lesvoyageurs.ci");
      }, 8000);
    }
  }, [navigate, saveSuccess]);

  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      {isLoadingData ? (
        <LoadingData />
      ) : (
        <div className="flex flex-col gap-8 items-center justify-center max-w-[500px] w-full mx-4 p-4">
          <div className="flex flex-row w-[80%] gap-2 items-center">
            <img className="w-full select-none" alt="" src={voyageurLogo} />
          </div>
          {!!decryptedData && !timestampExpired && !!station ? (
            <>
              <span className="flex text-black text-center w-[70%] select-none">
                Veuillez localiser la gare avant enregistrement
              </span>
              <div className="flex text-black items-center gap-2 flex-col -my-2">
                <span className="flex text-black font-bold text-[20px] select-none">
                  Nom de la gare :
                </span>
                <span className="flex text-black font-bold text-[22px] text-center">
                  {station.station_name}
                </span>
              </div>
              {station.station_locality && (
                <div className="flex text-black items-center gap-2 flex-col -my-2">
                  <span className="flex text-black font-medium text-[15px] select-none">
                    Derniere localisation enregistrer :
                  </span>
                  <span className="flex text-black font-medium text-[14px] text-center">
                    {station.station_locality}
                  </span>
                </div>
              )}
              <span className="flex text-black text-center w-[70%] select-none">
                {reponseData?.display_name ?? geolocationState.msg}
              </span>
              {!saveSuccess ? (
                <>
                  <button
                    onClick={
                      !isGeolocating ? requestGeolocationPermission : undefined
                    }
                    className="flex px-4 py-3 gap-2 items-center select-none bg-coral/10 active:bg-active/10 rounded-md"
                  >
                    {!isGeolocating ? (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="coral"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                          />
                        </svg>
                        <span className="flex text-black text-center">
                          Localiser ma position
                        </span>
                      </>
                    ) : (
                      <svg
                        className="animate-spin h-5 w-5 text-black/50"
                        xmlns="http://www.w3.org/2000/svg"
                        fill={"transparent"}
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke={primaryColor}
                          strokeWidth="2"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill={primaryColor}
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                  </button>
                  <button
                    onClick={
                      !!reponseData?.display_name ? updateStation : undefined
                    }
                    style={{
                      display: !!reponseData?.display_name ? "flex" : "none",
                    }}
                    className={`flex px-8 py-3 bg-coral active:bg-active rounded-md select-none`}
                  >
                    {!isUpdating ? (
                      <span className="flex text-white text-center">
                        Enregister la localisation
                      </span>
                    ) : (
                      <svg
                        className="animate-spin h-5 w-5 text-black/50"
                        xmlns="http://www.w3.org/2000/svg"
                        fill={"transparent"}
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke={"white"}
                          strokeWidth="2"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill={"white"}
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                  </button>
                </>
              ) : (
                <>
                  <span className="flex bg-green-500 cursor-pointer text-white hover:bg-green-400 p-2 px-4 -my-3 rounded-md text-center max-w-[70%] select-none">
                    Enregistrement reussie
                  </span>
                  <span className="flex text-black text-center w-[70%] select-none">
                    Vous serez redirigé dans quelques instants....
                  </span>
                </>
              )}
            </>
          ) : (
            <GeoolocationError />
          )}
        </div>
      )}
    </div>
  );
};

export default CheckGeolocation;
