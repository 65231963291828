import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import CheckGeolocation from "./pages/geolocation/contents/check";

function App() {
  return (
    <div className="flex flex-col min-h-screen h-full w-full font-inter scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-300 hover:scrollbar-thumb-gray-700 active:scrollbar-thumb-gray-700">
      <div className="flex flex-1 bg-gradient-to-b from-[#f5f5f5] to-white bg-cover bg-primary-contrast1">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/geolocation">
            <Route path="" element={<Navigate to={"../"} />} />
            <Route path="check" element={<CheckGeolocation />} />
          </Route>
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
